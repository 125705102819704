type FilterKey = 'city' | 'category'

export const replaceWithBr = (text: string, isEmptyP?: boolean) => {
  let regex = /\n/g
  if (isEmptyP) {
    regex = new RegExp('<p>&nbsp;</p>|<p>\\s</p>|<p></p>', 'g')
  }
  return text.replace(regex, '<br />')
}

export const stripeHtmlTag = (content: string = '') =>
  content.replace(/(<([^>]+)>)/gi, '')

export const timeout = (timeout = 10) => {
  return new Promise<void>((resolve) => {
    const t = setTimeout(() => {
      resolve()
      clearTimeout(t)
    }, timeout)
  })
}

export const getUniqData = (posts: any[], key: FilterKey, inArray?: boolean) => {
  return posts
    .reduce<string[]>((acc, item) => {
      if (inArray) {
        if (item[key] && item[key][0] && !acc.includes(item[key][0].title)) {
          acc = [...acc, item[key][0].title]
        }
      } else {
        if (item[key] && !acc.includes(item[key].title)) {
          acc = [...acc, item[key].title]
        }
      }

      return acc
    }, [])
    .map((d) => ({
      label: d,
      value: d,
    }))
}

export default function replaceNewslineWithBr(value: string) {
  if (!value) return value
  return value.replace(/\n/gim, '<br/>')
}

export const removeLastSlugSection = (urlPath: string) => {
  if (!urlPath) return "";
  if (urlPath === "") {
    return "";
  }
  const urlPathWithoutSlash = urlPath.replace(/\/$/, "");
  const urlPathSections = urlPathWithoutSlash.split("/");
  urlPathSections.pop();

  return `${urlPathSections.join("/")}/`;
};
